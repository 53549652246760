<template>
    
	<div class="cont sales payment list" id="p20">
		<div class="page-title">{{ program.name }}</div>

		<div class="c01">
			<div class="left">
				<div class="s01">
					<div class="c-item w100">
						<div class="title">등록일</div>
						<div class="content flex">
							<div class="cont-toggle">
								<div class="btn-toggle"
									:class="{ active: dateType == 'd'}"
									@click="setSearchDate('d')"
								>일간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'w'}"
									@click="setSearchDate('w')"
								>주간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'm'}"
									@click="setSearchDate('m')"
								>월간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'c'}"
									@click="setSearchDate('c')"
								>선택</div>
							</div>
							<div class="cont-date">
								<div class="parent-datepicker">
									<input type="text" class="datepicker"
										placeholder="시작일"
										v-model="search.inquiryStartDate"
										@click="setSearchDate('s'); search.inquiryStartDate = ''; datePicker.start.view = true"
										readonly
									>
									<v-date-picker
										v-if="datePicker.start.view"
										v-model="search.inquiryStartDate"
										no-title
										scrollable
										style="position: absolute; top: 120px; left: 460px; border: 1px solid #bbb;"
										@change="datePicker.start.view = false"
									></v-date-picker>
								</div>
								<span>~</span>
								<div class="parent-datepicker">
									<input type="text" class="datepicker"
										placeholder="종료일"
										v-model="search.inquiryEndDate"
										@click="setSearchDate('e'); search.inquiryEndDate = ''; datePicker.end.view = true"
										readonly
									>
									<v-date-picker
										v-if="datePicker.end.view"
										v-model="search.inquiryEndDate"
										no-title
										scrollable
										style="position: absolute; top: 120px; left: 660px; border: 1px solid #bbb;"
										@change="datePicker.end.view = false"
									></v-date-picker>
								</div>
							</div>
						</div>
					</div>
					<div class="c-item">
						<div class="title">상태</div>
						<div class="content">
							<select 
								v-model="search.status"
								@change="getData"
							>
								<option
									v-for="(item, index) in codes.payments.status"
									:key="index"
									:value="item.code"
									>{{ item.codeName }}</option>
							</select>
						</div>
					</div>
					<div class="c-item">
						<div class="title">서비스</div>
						<div class="content">
							<select 
								v-model="search.smsType"
								@change="getData"
							>
								<option
									v-for="(item, index) in codes.payments.smsType"
									:key="index"
									:value="item.code"
									>{{ item.codeName }}</option>
							</select>
						</div>
					</div>
					<div class="c-item">
						<div class="title">검색어</div>
						<div class="content">
							<input type="text" placeholder="영업점명 입력"
								v-model="search.keyword"
							>
						</div>
					</div>
				</div>
				<div class="s02">
					<a @click="getData"><div class="btn-search">조회</div></a>
					<a @click="clear"><div class="btn-reset">초기화</div></a>
					<a><div class="btn-excel">
						<vue-excel-xlsx
							:data="excel_items"
							:columns="columns"
							:filename="program.name"
							:sheetname="program.name"
						>
						엑셀 다운로드
						</vue-excel-xlsx>
					</div></a>
						
				</div>
			</div>
			<div class="right" style="width: 360px;">
				<div class="group g04">
					<div class="item">
						<div class="tit" style="margin: 20px 0 30px;">
							<img src="@/assets/img/icon-01.png" alt="">
							<div class="desc">집계</div>
						</div>
						<div class="content">
							<div class="amount">
								결제요청 
								<span class="cnt" style="font-size: 1.2em !important; margin-left: 10px;">{{ summary.requestCount | makeComma }}</span>건
								<span class="float-right">
									
									<span class="cnt">{{ summary.requestAmount | makeComma }}</span>원
								</span>
							</div>
							<div class="amount">
								결제실패 
								<span class="cnt" style="font-size: 1.2em !important; margin-left: 10px;">{{ summary.failCount | makeComma }}</span>건
								<span class="float-right">
									<span class="cnt">{{ summary.failAmount | makeComma }}</span>원
								</span>
							</div>
							<div class="amount">
								결제만료 
								<span class="cnt" style="font-size: 1.2em !important; margin-left: 10px;">{{ summary.expiryCount | makeComma }}</span>건
								<span class="float-right">
									<span class="cnt">{{ summary.expiryAmount | makeComma }}</span>원
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="c02">
			<div class="table type09">
				
				<div 
					v-if="items.length"
					class="text-right mb-10 ">
					<Pagination 
						:options="search"
					/>
				</div>
				<div class="item-header mt-10">
					<div class="item">
						<div class="td">NO</div>
						<div class="td">서비스 구분</div>
						<div class="td">고객명</div>
						<div class="td">구매자 연락처</div>
						<div class="td">상품명</div>
						<div class="td">공급가</div>
						<div class="td">부가세</div>
						<div class="td">결제금액</div>
						<div class="td">상태</div>
						<div class="td">결제요청일</div>
						<div class="td">상세</div>
					</div>
				</div>
				<div class="item-list">
					<div class="item"
						v-for="(item, index) in items"
						:key="index"
					>
						<div class="td">{{ item.rowNumber }}</div>
						<div class="td">{{ item.requestTypeName }}</div>
						<div class="td">{{ item.buyerName }}</div>
						<div class="td">{{ item.buyerContact | makeTell }}</div>
						<div class="td">{{ item.paymentProductName }}</div>
						<div class="td">{{ item.supplyAmount | makeComma }}원</div>
						<div class="td">{{ item.vatAmount | makeComma }}원</div>
						<div class="td">{{ item.paymentAmount | makeComma }}원</div>
						<div class="td">{{ item.statusName }}</div>
						<div class="td">{{ item.requestDateTime | transDateTime }}</div>
						<div class="td justify-center">
							<v-btn small class="success2"
								:to="{ name: 'PaymentsSmsDetail', params: { requestId: item.requestId, requestKey: item.requestKey } }"
							>상세보기</v-btn>
						</div>
					</div>
					<div
						v-show="!items.length"
					>
						<div class="no-data">No Data</div>
					</div>
				</div>
				
				<div 
					v-if="items.length"
					class="text-right mt-10">
					<Pagination 
						:options="search"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	import { date } from '@/resources/date/date'
	import { filter } from '@/resources/filter/filter'
	import { rules } from '@/resources/rules/rules'
	import { columns } from '@/resources/columns/columns'
	
	import Pagination from '@/components/Pagination'

	export default {
		name: 'PaymentsHistory'
		,props: ['user', 'codes']
		,components: { Pagination }
		,data: function(){
			return {
				program: {
					name: this.$language.menu[3].list[2].name
				}
				,summary: {
					paymentCount: 0
					,paymentAmount: 0
					,failCount: 0
					,failAmount: 0
					,expireCount: 0
					,expireAmount: 0
				}
				,items: []
				,excel_items: []
				,search: {
					inquiryStartDate: date.getSearchBaseDate()
					,inquiryEndDate: date.getToday()
					,status: ''
					,smsType: ''
				}
				,dateType: 'c'
				,today: date.getToday()
				,datePicker: {
					start: {
						view: false
					}
					,end: {
						view: false
					}
				}
				,rules: rules
				,columns: columns.Sales.smsFile
			}
		}
		,filter: filter
		,methods: {
			getData: async function(){
				const search = this.search
				try{
					const result = await Axios({
						method: 'get'
						,url: '/sms/history'
						,data: search
						,authorize: true
					})
					
					if(result.success){
						this.items = result.data.content.content
						this.$set(this.search, 'total_page', result.data.content.totalPages)
						this.$set(this.search, 'total_count', result.data.content.totalElements)
						await this.excel()
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E})
				}
			}
			,getSummary: async function(){
				const search = this.search
				try{
					const result = await Axios({
						method: 'get'
						,url: '/sms/summary'
						,data: search
						,authorize: true
					})
					
					if(result.success){
						this.summary = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E})
				}
			}
			,setSearchDate: async function(type){

				this.datePicker.start.view = false
				this.datePicker.end.view = false
				
				if(type == 'd'){
					this.$set(this.search, 'inquiryStartDate', date.getToday())
					this.$set(this.search, 'inquiryEndDate', date.getToday())
				}else if(type == 'w'){
					let dates = date.getWeeklyDate()
					this.$set(this.search, 'inquiryStartDate', dates.start)
					this.$set(this.search, 'inquiryEndDate', dates.end)
				}else if(type == 'm'){
					let dates = date.getMonthlyDate()
					this.$set(this.search, 'inquiryStartDate', dates.start)
					this.$set(this.search, 'inquiryEndDate', dates.end)
				}else if(type == 's'){
					this.$set(this.search, 'inquiryStartDate', '')
					type = 'c'
				}else if(type == 'e'){
					this.$set(this.search, 'inquiryEndDate', '')
					type = 'c'
				}else{
					this.$set(this.search, 'inquiryStartDate', date.getSearchBaseDate())
				}

				this.dateType = type

				if(this.search.inquiryStartDate && this.search.inquiryEndDate) {
					await this.getData()
				}
			}
			,clear: async function(){
				this.search = {
					inquiryStartDate: date.getSearchBaseDate()
					,inquiryEndDate: date.getToday()
					,status: ''
					,smsType: ''
					,keyword: ''
					,list_cnt: 20
				}
				this.setSearchDate('c')
			}
			,excel: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/sms/history/download'
						,data: this.search
						,authorize: true
					})

					if(result.success){
						this.excel_items = result.data.content.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.data.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
		}
		,created: async function(){
			await this.getSummary()
			await this.clear()
		}
		,watch: {
			'search.page': {
				handler: function(){
					this.getData()
				}
			}
			,'search.inquiryStartDate': {
				handler: function(call){
					if(call){
						this.search.inquiryStartDate = call.replaceAll('-', '')
					}
				}
			}
			,'search.inquiryEndDate': {
				handler: function(call){
					if(call){
						this.search.inquiryEndDate = call.replaceAll('-', '')
					}
				}
			}
		}
	}
</script>

<style>
	.sales.payment.list .item-header .item .td:nth-child(1) { width: 5% !important;}
	.sales.payment.list .item-header .item .td:nth-child(2) { width: 7% !important;}
	.sales.payment.list .item-header .item .td:nth-child(3) { width: 8% !important;}
	.sales.payment.list .item-header .item .td:nth-child(4) { width: 10% !important;}
	.sales.payment.list .item-header .item .td:nth-child(5) { width: 16% !important;}
	.sales.payment.list .item-header .item .td:nth-child(6) { width: 8% !important;}
	.sales.payment.list .item-header .item .td:nth-child(7) { width: 8% !important;}
	.sales.payment.list .item-header .item .td:nth-child(8) { width: 8% !important;}
	.sales.payment.list .item-header .item .td:nth-child(9) { width: 8% !important;}
	.sales.payment.list .item-header .item .td:nth-child(10) { width: 12% !important;}
	.sales.payment.list .item-header .item .td:nth-child(11) { width: 10% !important;}
	
	.sales.payment.list .item-list .item .td:nth-child(1) { width: 5% !important;}
	.sales.payment.list .item-list .item .td:nth-child(2) { width: 7% !important;}
	.sales.payment.list .item-list .item .td:nth-child(3) { width: 8% !important;}
	.sales.payment.list .item-list .item .td:nth-child(4) { width: 10% !important;}
	.sales.payment.list .item-list .item .td:nth-child(5) { width: 16% !important;}
	.sales.payment.list .item-list .item .td:nth-child(6) { width: 8% !important;}
	.sales.payment.list .item-list .item .td:nth-child(7) { width: 8% !important;}
	.sales.payment.list .item-list .item .td:nth-child(8) { width: 8% !important;}
	.sales.payment.list .item-list .item .td:nth-child(9) { width: 8% !important;}
	.sales.payment.list .item-list .item .td:nth-child(10) { width: 12% !important;}
	.sales.payment.list .item-list .item .td:nth-child(11) { width: 10% !important; padding-left: 0 !important;}
	
	.sales.payment.list .item-list .item .td { justify-content: center !important; padding-left: 0 !important; padding-right: 0 !important;}
	
	.sales.payment.list .group .item { width: 100%;}
	.sales.payment.list .amount { font-size: 1em !important; margin-bottom: 20px;}
	.sales.payment.list .cnt { font-size: 1.5em !important; margin-right: 1px;}
</style>





















